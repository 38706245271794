import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import FlightRow from './checkriderow.jsx'
import logo from './Icon-1024x1024-Full-White-Version.png'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { createMuiTheme, ThemeProvider} from '@material-ui/core';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card'
import { textAlign } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';
const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#ffffff'
      },
      secondary: {
        main: '#ffffff'
      }
    }
  });
class Checkrides extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            checkride : [],
            searchCode : "",
            loading : false,
            loaded : false,
            errorOpen:false,
            authCode : ""
        }
        this.getCheckride = this.getCheckride.bind(this)
        this.updateSearchCode = this.updateSearchCode.bind(this)
        this.endLoading = this.endLoading.bind(this)
    }
    componentDidMount(){
        let a = this
        let db = firebase.firestore()
        db.collection("flightOperations").doc("secretCode").onSnapshot((doc) => {
            this.setState({authCode : doc.data().code})
        });
    }
    getCheckride(){
        //this.setState({loading:true})
        let db = firebase.firestore()
        let flightRef = db.collection('checkrides').doc(this.state.searchCode)
        flightRef.get().then((doc)=>{
            if(doc.exists){
            let checkRideRow = <FlightRow id = {doc.id} flightID = {doc.data().flightID} aircraftType = {doc.data().aircraftType} alternateAirport = {doc.data().alternateAirport}
                    cruiseAlt = {doc.data().cruiseAlt} departure = {doc.data().departure} departureTime = {doc.data().departureTime }
                    destination = {doc.data().destination} dist = {doc.data().dist} enRouteHours={doc.data().enRouteHours} enRouteMinuites={doc.data().enRouteMinuites}
                    fuelOnBoardHours = {doc.data().fuelOnBoardHours} fuelOnBoardMins = {doc.data().fuelOnBoardMins} gph = {doc.data().gph}
                    lastEditied = {doc.data().lastEditied} pilotName = {doc.data().pilotName} remarks = {doc.data().remarks} routeOfFlight = {doc.data().routeOfFlight}
                    soulsAbord = {doc.data().soulsAbord} timeOff = {doc.data().timeOff} type = {doc.data().type} waypoints = {doc.data().waypoints} aircraftId = {doc.data().aircraftId} tas = {doc.data().TAS}></FlightRow>
                    this.setState({checkride : checkRideRow,loading:true, loaded:true})
                    document.body.style = 'background: #ffffff;';
            }else{
                this.setState({errorOpen:true})
            }

        })
    }
    endLoading(){
        document.body.style = 'background: #ffffff;';
    }
    updateSearchCode(event){
        this.setState({searchCode : event.target.value})
    }
    render(){
        document.body.style = 'background: #00559a;';
        return(
            <div>
                <div hidden = {this.state.loading==true} style = {{marginTop:"15vh", alignItems:'center'}}>
                <h3 style={{textAlign: "center",color:'white'}}>Flight Supervisor Code : {this.state.authCode}</h3>
                <img src={logo} width="200" height="200" alignSelf="center" style={{marginLeft:'45vw'}}></img>
                <h1 style={{textAlign: "center",color:'white'}} hidden = {this.state.loading==true}>Checkride Request</h1>
                <ThemeProvider theme={theme}>
                <TextField  id = "checkrideCode" label = "Code" variant="outlined" onChange={this.updateSearchCode} style = {{marginLeft:'45vw'}} hidden = {this.state.loading==true}></TextField>
                </ThemeProvider>
                <p></p>
                <Button variant = "contained"  onClick={this.getCheckride} hidden = {this.state.loading==true} style = {{marginLeft:'47vw'}}>Submit Code</Button>
                </div>
                <div>
                
                </div>
                <div hidden = {this.state.loaded == false}>
                    {this.state.checkride}
                </div>



                <Modal open={this.state.errorOpen} onClose={()=>this.setState({errorOpen:false})} aria-labelledby="Flight Plan Not Found"
                aria-describedby="Flight Plan Not Found">
                    <Card style ={{alignItems : 'center', width:'40vw',marginLeft:'30vw',marginTop:"25vh"}}>
                        <h3 style={{textAlign:'center'}}>Flight Plan Not Found</h3>
                        <p style={{textAlign:'center'}}>A flight plan was not found with the provided code.  Please try again.</p>
                        <Button  variant = "contained" style={{width:'100%'}} onClick={()=>this.setState({errorOpen:false})}>OK</Button>
                    </Card>
                </Modal>
            </div>
        )
    }
}

export default Checkrides