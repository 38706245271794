import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TextField, useTheme, Button } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import UserRow from './userRow'
class UsersTable extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            orgUsers : [],
            userFlights : <h1>not dic</h1>,
            searchTerm : ""
        }
        this.accordionOpened = this.accordionOpened.bind(this)
        this.searchButtonClicked = this.searchButtonClicked.bind(this)
        this.searchTextBoxChanged = this.searchTextBoxChanged.bind(this)
    }

    componentDidMount(){
        let db = firebase.firestore()
        let usersRef = db.collection("users")
        let query = usersRef.where("orgCode", "==", "QKDI")
        let that = this
        var usersCarry = []
        usersRef.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                console.log(doc.id, doc.data())
               //usersCarry.push(<TableRow><TableCell>{doc.id}</TableCell></TableRow>)
                usersCarry.push(<UserRow user = {doc.id}></UserRow>)


               that.setState({orgUsers : usersCarry})
            })
        })

        
        
    }
    accordionOpened(id){
        console.log("accordion opened! " + id)
        let floating  = []
        for(var i = 0; i < this.state.orgUsers.length; i = i+1){
            if(this.state.orgUsers[i].key == id){
                console.log("Found key!")
                this.setState({userFlights : <h1>Dick</h1>})
            }else{
                
            }
        }
    }
    searchButtonClicked(){
        let db = firebase.firestore()
        let usersRef = db.collection("users")
        let query = usersRef.where("orgCode", "==", "QKDI")
        let that = this
        var usersCarry = []
        query.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                console.log(doc.id, doc.data())
               //usersCarry.push(<TableRow><TableCell>{doc.id}</TableCell></TableRow>)
               if(doc.id.includes(this.state.searchTerm)){
                    usersCarry.push(<UserRow user = {doc.id}></UserRow>)
               }
               that.setState({orgUsers : usersCarry})
            })
        })
    }
    searchTextBoxChanged(event){
        console.log(event.target.value)
        this.setState({searchTerm : event.target.value})
    }
    render(){
        return(
            <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                      Users
                      <TableCell><TextField placeholder = {"Search"} onChange = {this.searchTextBoxChanged}></TextField><Button onClick = {this.searchButtonClicked}>Search</Button></TableCell>
                    </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.orgUsers}
              </TableBody>
            </Table>
          </TableContainer>
        )
    }
}

export default UsersTable