import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TextField, useTheme } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import FlightRow from './flightRow'
class UserRow extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            user : props.user,
            flights : [],
            opened : true
        }
    }
    accordionOpened(id){
        if(this.state.opened != true){
            console.log("accordion closed! " + this.state.user)
            this.setState({opened : true})
        }else{
            let flightsCarry = []
            console.log("accordion opened! " + this.state.user)
            this.setState({opened : false})
            let db = firebase.firestore()
            let flightRef = db.collection("users").doc(this.state.user).collection("flights")
            flightRef.get().then((querySnapshot)=>{
                querySnapshot.forEach((doc) => {
                    console.log(doc.id, doc.data().waypoints)
                    flightsCarry.push(<FlightRow id = {doc.id} aircraftType = {doc.data().aircraftType} alternateAirport = {doc.data().alternateAirport}
                    cruiseAlt = {doc.data().cruiseAlt} departure = {doc.data().departure} departureTime = {doc.data().departureTime }
                    destination = {doc.data().destination} dist = {doc.data().dist} enRouteHours={doc.data().enRouteHours} enRouteMinuites={doc.data().enRouteMinuites}
                    fuelOnBoardHours = {doc.data().fuelOnBoardHours} fuelOnBoardMins = {doc.data().fuelOnBoardMins} gph = {doc.data().gph}
                    lastEditied = {doc.data().lastEditied} pilotName = {doc.data().pilotName} remarks = {doc.data().remarks} routeOfFlight = {doc.data().routeOfFlight}
                    soulsAbord = {doc.data().soulsAbord} timeOff = {doc.data().timeOff} type = {doc.data().type} waypoints = {doc.data().waypoints} aircraftId = {doc.data().aircraftId} tas = {doc.data().TAS}></FlightRow>)
                    this.setState({flights : flightsCarry})
                })
            })
        }
    }
    render(){
        return(
            <TableRow key = {this.state.user}>
            <TableCell>
                <Accordion onChange = {() => this.accordionOpened(this.state.user)}>
                    <AccordionSummary  expandIcon={<ExpandMoreIcon />} aria-controls={this.state.user} id={this.state.user}>
                        <Typography>{this.state.user}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody>
                                {this.state.flights}
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            </TableCell>
            </TableRow>
        )
    }
}

export default UserRow