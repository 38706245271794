import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
const firebaseConfig = {
  apiKey: "AIzaSyDN5Da3wv0d14fgNesNioafj_RxlFkpiY4",
  authDomain: "flightplan-bb655.firebaseapp.com",
  projectId: "flightplan-bb655",
  storageBucket: "flightplan-bb655.appspot.com",
  messagingSenderId: "912554837487",
  appId: "1:912554837487:web:50a55b5385956ae7d6221a",
  measurementId: "G-P2117TZTEW"
};

firebase.initializeApp(firebaseConfig)
//export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const db = firebase.firestore();

ReactDOM.render(
    <App />
 ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
