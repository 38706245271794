import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TextField, Button } from '@material-ui/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import FlightPlanSummary from './flightPlanSummary';
class FlightPlanTable extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            userSearch : "reesj4@my.erau.edu"
        }

        this.getUserFlightPlans = this.getUserFlightPlans.bind(this)
    }

    componentDidMount(){
        let db = firebase.firestore()
        let usersRef = db.collection("users")
        let orgCode = "QKDI"
        let query = usersRef.where("orgCode", "==", orgCode)
        query.get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                console.log(doc.id, doc.data())
            })
        })
    }

    getUserFlightPlans(){
        let db = firebase.firestore()
        let usersRef = db.collection("users").collection(this.state.userSearch)
        
    }

    render(){
        return(<TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                      Flight Plans
                      <TableCell><TextField></TextField></TableCell>
                    </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <FlightPlanSummary></FlightPlanSummary>
              </TableBody>
            </Table>
          </TableContainer>
            
                
            
        )
    }
}

export default FlightPlanTable