import React from 'react';
import Card from '@material-ui/core/Card';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Divider } from '@material-ui/core';
class FlightPlanSummary extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            aircraftType : props.aircraftType,
            alternateAirport : props.alternateAirport,
            cruiseAlt : props.cruiseAlt,
            departure : props.departure,
            departureTime : props.departureTime,
            destination : props.destination,
            dist : props.dist,
            enRouteHours : props.enRouteHours,
            enRouteMinuites : props.enRouteMinuites,
            fuelOnBoardHours : props.fuelOnBoardHours,
            fuelOnBoardMins : props.fuelOnBoardMins,
            gph : props.gph,
            lastEditied : props.lastEditied,
            pilotName : props.pilotName,
            remarks : props.remarks,
            routeOfFlight : props.routeOfFlight,
            soulsAbord : props.soulsAbord,
            timeOff : props.timeOff,
            type : props.type,
            aircraftId : props.aircraftId,
            tas : props.tas,
            waypoints : props.waypoints,
            wpDisplay : []
        }
    }
    componentDidMount(){
        var wpCarry = []
        for(var i = 0; i < this.state.waypoints.length; i = i + 1){
            console.log(this.state.waypoints[i])
            wpCarry.push(<TableRow>
                <TableCell>{this.state.waypoints[i].checkPoint}</TableCell>
                <TableCell><Typography>{this.state.waypoints[i].ident}</Typography><Typography>{this.state.waypoints[i].freq}</Typography></TableCell>
                <TableCell>{this.state.waypoints[i].course}</TableCell>
                <TableCell>{this.state.waypoints[i].alt}</TableCell>
                <TableCell style = {{alignContent : 'center'}}>{this.state.waypoints[i].dir} | {this.state.waypoints[i].vel}<p>{this.state.waypoints[i].temp}</p></TableCell>
                <TableCell>{this.state.waypoints[i].tas}</TableCell>
                <TableCell>{this.state.waypoints[i].tc}</TableCell>
                <TableCell>{this.state.waypoints[i].th}</TableCell>
                <TableCell>{this.state.waypoints[i].mh}</TableCell>
                <TableCell>{this.state.waypoints[i].ch}</TableCell>
                <TableCell>{this.state.waypoints[i].leg}</TableCell>
                <TableCell>{this.state.waypoints[i].gs}</TableCell>
                <TableCell><p>{this.state.waypoints[i].ETE}  {this.state.waypoints[i].eta}</p><p>{this.state.waypoints[i].ate}  {this.state.waypoints[i].ata}</p></TableCell>
                <TableCell><p>{this.state.waypoints[i].fuel}</p><p>{this.state.waypoints[i].fuelRem}</p></TableCell>
              </TableRow>)
            this.setState({wpDisplay : wpCarry})
        }
    }
    render(){
        return(
            <div>
            <Card style = {{backgroundColor : "black", marginTop : '15px'}}>
                <Grid>
                    <Grid container>

                        <Grid xs = {12}>
                            <Card style = {{height : '50px', backgroundColor : "grey", alignItems : "Center"}}>
                                <h4>FLIGHT PLAN</h4>
                            </Card>
                        </Grid>

                        <Grid xs = {1}>
                            <Card style = {{height : '75px', marginTop : '5px'}}>
                                <h5 style = {{marginTop : '1px'}}>1. Type</h5>
                                <h5>{this.state.type}</h5>
                            </Card>
                        </Grid>
                        <Grid xs = {2}>
                            <Card style = {{height : '75px', marginTop : '5px'}}>
                                <h5  style = {{marginTop : '1px'}}>2. Aircraft Identification</h5>
                                <h5>{this.state.aircraftId}</h5>
                            </Card>
                        </Grid>
                        <Grid xs = {2}>
                            <Card style = {{height : '75px', marginTop : '5px'}}>
                                <h5  style = {{marginTop : '1px'}}>3. Aircraft Type/ Special Equipment</h5>
                                <h5>{this.state.aircraftType}</h5>
                            </Card>
                        </Grid>
                        <Grid xs = {2}>
                            <Card style = {{height : '75px', marginTop : '5px'}}>
                                <h5 style = {{marginTop : '1px'}}>4. True Airspeed</h5>
                                <h5>{this.state.tas} Knots</h5>
                            </Card>
                        </Grid>
                        <Grid xs = {2}>
                            <Card style = {{height : '75px', marginTop : '5px'}}>
                                <h5 style = {{marginTop : '1px'}}>5. Departure Point</h5>
                                <h5>{this.state.departure}</h5>
                            </Card>
                        </Grid>
                        <Grid xs = {2}>
                            <Card style = {{height : '75px', marginTop : '5px'}}>
                                <Card style = {{height : '25px', backgroundColor : "grey"}}>
                                    <h5 style = {{marginTop : '1px'}}>6. Departure Time</h5>
                                </Card>
                                <Grid container  style = {{marginTop : '15px'}}>
                                    <Grid item xs = {6}>
                                        <h5  style = {{marginTop : '-15px'}}>Proposed (Z)</h5>
                                        <h5  style = {{marginTop : '-15px'}}>{this.state.departureTime}</h5>
                                    </Grid>
                                    <Grid item xs = {6}>
                                        <h5  style = {{marginTop : '-15px'}}>Actual (Z)</h5>
                                        <h5  style = {{marginTop : '-15px'}}>1315</h5>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid xs = {1}>
                            <Card style = {{height : '75px', marginTop : '5px'}}>
                                <h5 style = {{marginTop : '1px'}}>7. Cruising Alt</h5>
                                <h5>{this.state.cruiseAlt}</h5>
                            </Card>
                        </Grid>

                        <Grid xs = {12}>
                            <Card style = {{height : '75px', marginTop : '5px', alignItems : 'left'}}>
                                <h5 style = {{marginTop : '1px'}}>8. Route of Flight</h5>
                                <h5>{this.state.routeOfFlight}</h5>
                            </Card>
                        </Grid>

                        <Grid xs = {5}>
                            <Card style = {{height : '75px', marginTop : '5px', alignItems : 'left'}}>
                                <h5 style = {{marginTop : '1px'}}>9. Destination (Name of Airport and City</h5>
                                <h5>{this.state.destination}</h5>
                            </Card>
                        </Grid>
                        <Grid xs = {2}>
                            <Card style = {{height : '75px', marginTop : '5px', alignItems : 'left'}}>
                                <Card style = {{height : '25px', backgroundColor : "grey"}}>
                                    <h5 style = {{marginTop : '1px'}}>10. Est. Time Enroute</h5>
                                </Card>
                                <Grid container  style = {{marginTop : '15px'}}>
                                    <Grid item xs = {6}>
                                        <h5  style = {{marginTop : '-15px'}}>Hours</h5>
                                        <h5  style = {{marginTop : '-15px'}}>{this.state.enRouteHours}</h5>
                                    </Grid>
                                    <Grid item xs = {6}>
                                        <h5  style = {{marginTop : '-15px'}}>Minutes</h5>
                                        <h5  style = {{marginTop : '-15px'}}>{this.state.enRouteMinuites}</h5>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid xs = {5}>
                            <Card style = {{height : '75px', marginTop : '5px', alignItems : 'left'}}>
                                <h5 style = {{marginTop : '1px'}}>11. Remarks</h5>
                                <h5>{this.state.remarks}</h5>
                            </Card>
                        </Grid>

                        <Grid xs = {3}>
                            <Card style = {{height : '75px', marginTop : '5px', alignItems : 'left'}}>
                                <Card style = {{height : '25px', backgroundColor : "grey"}}>
                                    <h5 style = {{marginTop : '1px'}}>12. Fuel on Board</h5>
                                </Card>
                                <Grid container  style = {{marginTop : '15px'}}>
                                    <Grid item xs = {6}>
                                        <h5  style = {{marginTop : '-15px'}}>Hours</h5>
                                        <h5  style = {{marginTop : '-15px'}}>{this.state.enRouteHours}</h5>
                                    </Grid>
                                    <Grid item xs = {6}>
                                        <h5  style = {{marginTop : '-15px'}}>Minutes</h5>
                                        <h5  style = {{marginTop : '-15px'}}>{this.state.enRouteMinuites}</h5>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid xs = {3}>
                            <Card style = {{height : '75px', marginTop : '5px', alignItems : 'left'}}>
                                <h5 style = {{marginTop : '1px'}}>13. Alternate Airport(s)</h5>
                                <h5>{this.state.alternateAirport}</h5>
                            </Card>
                        </Grid>
                        <Grid xs = {5}>
                            <Card style = {{height : '75px', marginTop : '5px', alignItems : 'left'}}>
                                <h5 style = {{marginTop : '1px'}}>14. Pilot's Name</h5>
                                <h5>{this.state.pilotName}</h5>
                            </Card>
                        </Grid>
                        <Grid xs = {1}>
                            <Card style = {{height : '75px', marginTop : '5px', alignItems : 'left'}}>
                                <h5 style = {{marginTop : '1px'}}>15. # Aboard</h5>
                                <h5>{this.state.soulsAbord}</h5>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            <Card style={{marginTop : '10px'}}>
            <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>CheckPoints (Fixes)</TableCell>
                  <TableCell><Typography>VOR</Typography><Typography>Ident.</Typography><Typography>Freq.</Typography></TableCell>
                  <TableCell>Course(Route)</TableCell>
                  <TableCell>Altitude</TableCell>
                  <TableCell>Wind<p>Dir | Vel</p><p>Temp</p></TableCell>
                  <TableCell><Typography>CAS</Typography><h4>{this.state.tas}</h4><Typography>TAS</Typography></TableCell>
                  <TableCell>TC</TableCell>
                  <TableCell>TH</TableCell>
                  <TableCell>MH</TableCell>
                  <TableCell>CH</TableCell>
                  <TableCell>Dist.</TableCell>
                  <TableCell>GS</TableCell>
                  <TableCell><p>Time Off</p><p>ETE  |  ETA</p><p>ATE  |  ATA</p></TableCell>
                  <TableCell><Typography>GPH</Typography><h4>{this.state.gph}</h4><Typography>Fuel</Typography><Typography>Rem</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.wpDisplay}
              </TableBody>
            </Table>
          </TableContainer>
            </Card>
            </div>
        )

    }
}

export default FlightPlanSummary