import logo from './logo.svg';
import './App.css';
import FlightPlanSummary from './flightPlanSummary';
import FlightPlanTable from './flightPlanTable';
import UsersTable from './usersTable'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Component } from 'react';
import { AppBar } from '@material-ui/core';
import RequestTable from './requestTable';
import Checkrides from './checkrides';
class App extends Component {
  constructor(props){
    super(props)
    this.state = {
      tabSelected : 0
    }
    this.handelChange = this.handelChange.bind(this)
  }

  handelChange(event, newValue){
    console.log(event)
    console.log(newValue)
    this.setState({tabSelected:newValue})
  }
  render(){
    /*
  return (
    <div className="App">
      <Tabs value = {this.state.tabSelected} onChange = {this.handelChange}>
          <Tab label="Students" disabled = {false} value = {0}></Tab>
          <Tab label="Requested" disabled = {false} value = {1}></Tab>
          <Tab label="Authorized" disabled = {false}></Tab>
          <Tab label="Dispatched" disabled = {false}></Tab>
          <Tab label="CheckRides" disabled = {false} value = {4}></Tab>
      </Tabs> 

      <div hidden = {this.state.tabSelected != 0}>
        <UsersTable></UsersTable>
      </div>
      <div hidden = {this.state.tabSelected != 1}>
        <RequestTable></RequestTable>
      </div>
      <div hidden = {this.state.tabSelected != 4}>
        <Checkrides></Checkrides>
      </div>
    </div>
  );
  */
    return(
      <Checkrides></Checkrides>
    )
  }
}

export default App;
