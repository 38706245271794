import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import RequestCell from './requestCell';
import { DockSharp } from '@material-ui/icons';
class RequestTable extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            requests : [],
            requestInfo : []
        }
        this.loadAll = this.loadAll.bind(this)
    }
    componentDidMount(){
        this.loadAll()
    }
    loadAll(){
        var requests = []
        let db = firebase.firestore()
        let reqRef = db.collection('requests')
        var query = reqRef.where("Status","==","Pending Authorization")
        query.get().then((querySnapshot)=>{
            querySnapshot.forEach((doc) => {
                console.log(doc.data())
                requests.push(<RequestCell Aircraft = {doc.data().Aircraft} Date = {doc.data().Date} Destination = {doc.data().Destination} DueBack = {doc.data.DueBack} IPSignature = {doc.data.IPSignature} 
                Status = {doc.data().Status} currentCharts = {doc.data().currentCharts} dualTrainingReceived = {doc.data().dualTrainingReceived} eta = {doc.data.eta} groundTime = {doc.data().groundTime}
                instructor = {doc.data().instructor} legs = {doc.data().legs} medCert = {doc.data().medCert} minFuel = {doc.data().minFuel} nientyDay = {doc.data().nientyDay} pilotCert = {doc.data().pilotCert}
                pointsOfRefuel = {doc.data().pointsOfRefuel} pq = {doc.data().pq} requriedTCO = {doc.data().requriedTCO} restrictions = {doc.data().restrictions} review = {doc.data().review}
                sixtyOneNinetyThree = {doc.data().sixtyOneNinetyThree} soloXC = {doc.data().soloXC} soloXCEndorsement = {doc.data().soloXCEndorsement} student = {doc.data().student} 
                studentSignature = {doc.data().studentSignature} totalTime = {doc.data().totalTime} totalTimeEnRoute = {doc.data().totalTimeEnRoute} tt = {doc.data().tt}
                unit = {doc.data().unit} user = {doc.data().user}></RequestCell>)
                this.setState({requests:requests})
            })
        })
    }
    render(){
        return(
        <TableContainer>
            <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Student</TableCell>
                    <TableCell>Instructor</TableCell>
                    <TableCell>Departure</TableCell>
                    <TableCell>Destination</TableCell>
                    <TableCell>Approve</TableCell>
                </TableRow>
            </TableHead>
            {this.state.requests}
            </Table>
        </TableContainer>
        )
    }
}

export default RequestTable

