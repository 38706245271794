import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TextField, useTheme } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import Backdrop from '@material-ui/core/Backdrop';
import FlightPlanSummary from './flightPlanSummary';
import Moment from 'react-moment';
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Document, Page } from 'react-pdf';
class FlightRow extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            aircraftType : props.aircraftType,
            alternateAirport : props.alternateAirport,
            cruiseAlt : props.cruiseAlt,
            departure : props.departure,
            departureTime : props.departureTime,
            destination : props.destination,
            dist : props.dist,
            enRouteHours : props.enRouteHours,
            enRouteMinuites : props.enRouteMinuites,
            fuelOnBoardHours : props.fuelOnBoardHours,
            fuelOnBoardMins : props.fuelOnBoardMins,
            gph : props.gph,
            lastEditied : props.lastEditied,
            pilotName : props.pilotName,
            remarks : props.remarks,
            routeOfFlight : props.routeOfFlight,
            soulsAbord : props.soulsAbord,
            timeOff : props.timeOff,
            type : props.type,
            aircraftId : props.aircraftId,
            tas : props.tas,
            waypoints : props.waypoints,
            tabSelected : 0,
            id : props.id,
            flightID : props.flightID,
            pdfs : []
        }
        this.handelChange = this.handelChange.bind(this)
        this.getDocuments = this.getDocuments.bind(this)
    }
    componentDidMount(){
        this.getDocuments()
        //window.open("https://firebasestorage.googleapis.com/v0/b/flightplan-bb655.appspot.com/o/flightFiles%2FNlQ1VYVRKbltOF7NMTNW%2F1651016121.098367.pdf?alt=media&token=ef24e606-9597-490b-8a40-585def177d22", "_blank", "noopener noreferrer");
    }
    getDocuments(){
        let a = this
        var storage = firebase.storage();
        var pathReference = storage.ref('/flightFiles/'+this.state.flightID)
        pathReference.listAll().then((res)=>{
            console.log('STORAGE SUCCESS!')
            console.log(this.state.id)
            var pdfs = []
            res.items.forEach((itemRef) => {
               itemRef.getDownloadURL().then(result=>window.open(result, "_blank", "noopener noreferrer"))
               //itemRef.getDownloadURL().then(result=>window.open(result))
            a.setState({pdfs : pdfs})
            });
        }).catch((error) => {
            // Uh-oh, an error occurred!
            console.log('STORAGE ERROR')
            console.log(error)
          });
    }
    handelChange(event, newValue){
        this.setState({tabSelected:newValue})
    }
    render(){
        return(
                <div>
                    
                   


                   
                    <h1>Last Editied : <Moment unix>{this.state.lastEditied}</Moment></h1>
                    <FlightPlanSummary aircraftType = {this.state.aircraftType} alternateAirport = {this.state.alternateAirport}
                    cruiseAlt = {this.state.cruiseAlt} departure = {this.state.departure} departureTime = {this.state.departureTime }
                    destination = {this.state.destination} dist = {this.state.dist} enRouteHours={this.state.enRouteHours} enRouteMinuites={this.state.enRouteMinuites}
                    fuelOnBoardHours = {this.state.fuelOnBoardHours} fuelOnBoardMins = {this.state.fuelOnBoardMins} gph = {this.state.gph}
                    lastEditied = {this.state.lastEditied} pilotName = {this.state.pilotName} remarks = {this.state.remarks} routeOfFlight = {this.state.routeOfFlight}
                    soulsAbord = {this.state.soulsAbord} timeOff = {this.state.timeOff} type = {this.state.type} waypoints = {this.state.waypoints} aircraftId = {this.state.aircraftId} tas = {this.state.tas}></FlightPlanSummary>
                    
                
                </div>    



        )
    }
}

export default FlightRow