import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from "@mui/material/Button"
class RequestCell extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            Aircraft : props.Aircraft,
            Date : props.Date,
            Destination : props.Destination,
            Departure : props.Departure,
            DueBack : props.DueBack,
            IPSignature : props.IPSignature,
            Status : props.Status,
            currentCharts : props.currentCharts,
            dualTrainingReceived : props.dualTrainingReceived,
            eta : props.eta,
            groundTime : props.groundTime,
            instructor : props.instructor,
            legs : props.legs,
            medCert : props.medCert,
            minFuel : props.minFuel,
            nientyDay : props.nientyDay,
            pilotCert: props.pilotCert,
            pointsOfRefuel : props.pointsOfRefuel,
            pq : props.pq,
            requiredTCO : props.requiredTCO,
            restrictions : props.restrictions,
            review : props.review,
            sixtyOneNinetyThree : props.sixtyOneNinetyThree,
            soloXC : props.soloXC,
            soloXCEndorsement : props.soloXCEndorsement,
            student : props.student,
            studentSignature : props.studentSignature,
            totalTime : props.totalTime,
            totalTimeEnRoute : props.totalTimeEnRoute,
            tt : props.tt,
            unit : props.unit,
            user : props.user
        }
    }
    render(){
        return(
            <TableRow>
                <TableCell>{this.state.Date}</TableCell>
                <TableCell>{this.state.student}</TableCell>
                <TableCell>{this.state.instructor}</TableCell>
                <TableCell>{this.state.Departure}</TableCell>
                <TableCell>{this.state.Destination}</TableCell>
                <TableCell><Button>Approve</Button></TableCell>
            </TableRow>
        )
    }
}

export default RequestCell